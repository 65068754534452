import React from "react";
import { Skeleton, Card, Row, Col } from "antd";

const BlogArticlesSkeleton = ({ itemCount = 5 }) => {
  return (
    <Row gutter={[16, 16]}>
      {[...Array(6)].map((_, index) => (
        <Col xs={24} sm={12} md={8} key={index}>
          <Card style={{ width: "100%" }}>
            <Skeleton active title={false} paragraph={{ rows: 3 }} />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default BlogArticlesSkeleton;
