import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import useQueryClient from "./useQueryClient";

const useQueryRequest = ({
  enabled = true,
  queryKey,
  queryFn,
  staleTime,
  refetchInterval,
}) => {
  const client = useQueryClient();

  const { data, isLoading, refetch, isFetching, isError, error } = useQuery({
    enabled,
    queryKey,
    queryFn,
    staleTime: !staleTime
      ? client.getDefaultOptions().queries.staleTime
      : staleTime,
    refetchInterval,
  });

  useEffect(() => {
    if (!isError) {
      return;
    }
    if (!error) {
      return;
    }
    console.log("🚀 ~ useEffect ~ error:", error);
  }, [isError, error]);

  return {
    data,
    isLoading: isFetching,
    isLoadingByQuery: isLoading,
    refetch,
  };
};

export default useQueryRequest;
