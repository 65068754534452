import React, { useState } from "react";
import { Popconfirm } from "antd";
import { FiHeart, FiTrash2, FiEdit3 } from "react-icons/fi"; // Feather icons
import "./BlogArticleActions.scss";
import useMutationRemoveArticles from "../../../utils/hooks/useMutationRemoveArticles";
import useModalActionTypeStore, {
  selectSetModalActionType,
} from "../../../utils/hooks/useModalActionTypeStore";
import blogArticlesModalActionTypes from "../../../utils/constants/blogArticlesModalActionTypes";

const BlogArticleActions = ({ article, handleEditArticleClick }) => {
  const setModalActionType = useModalActionTypeStore(selectSetModalActionType);
  const [isArticleLiked, setIsArticleLiked] = useState(false);

  const handleLike = () => {
    setIsArticleLiked(!isArticleLiked);
  };
  const { removeArticles } = useMutationRemoveArticles();

  const handleDeleteArticle = () => {
    const token = localStorage.getItem("jwt");

    removeArticles({ token, id: article?.id });
  };

  const handleEditArticle = () => {
    setModalActionType({ action: blogArticlesModalActionTypes.edit.action });
    handleEditArticleClick(article.id);
  };
  return (
    <div
      className="blog-actions_icon-buttons__wrapper"
      onClick={(event) => event.stopPropagation()}
    >
      <button
        className={`blog-actions_icon-button ${
          isArticleLiked ? "isArticleLiked" : ""
        }`}
        onClick={handleLike}
      >
        <FiHeart />
      </button>
      <button onClick={handleEditArticle} className="blog-actions_icon-button">
        <FiEdit3 />
      </button>
      <Popconfirm
        title="¿Estás seguro de que quieres eliminar este artículo?"
        onConfirm={handleDeleteArticle}
        okText="Eliminar"
        cancelText="Cancelar"
      >
        <button className="blog-actions_icon-button">
          <FiTrash2 />
        </button>
      </Popconfirm>
    </div>
  );
};

export default BlogArticleActions;
