import React, { useEffect, useState } from "react";
import BlogArticleActions from "./components/BlogArticleActions";
import useLoginStore, {
  selectLoginInfo,
} from "../../../login/utils/hooks/useLoginStore";
import "./blog-article.scss";

const BlogArticle = ({
  article,
  handleSelectedArticle,
  handleEditArticleClick,
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const { isLoggedIn } = useLoginStore(selectLoginInfo);

  useEffect(() => {
    if (article.headerImage && article.headerImage.data) {
      const blob = new Blob([new Uint8Array(article.headerImage.data.data)], {
        type: article.headerImage.contentType,
      });
      const url = URL.createObjectURL(blob);
      setImageUrl(url);

      return () => URL.revokeObjectURL(url);
    } else {
      setImageUrl(`img/blog/blog-post-1.jpg`);
    }
  }, [article]);

  return (
    <article
      className="blog-page_article-card"
      onClick={() => handleSelectedArticle(article.id)}
    >
      <div className="blog-page_article-card__post-thumbnail">
        <div className="d-block position-relative overflow-hidden">
          {isLoggedIn && (
            <BlogArticleActions
              article={article}
              handleEditArticleClick={handleEditArticleClick}
            />
          )}
          <img src={imageUrl} alt="Article Thumbnail" />
        </div>
      </div>
      <div className="blog-page_article-card__title-wrapper">
        <div className="blog-page_article-card__title">
          <h3>{article.title}</h3>
        </div>
      </div>
    </article>
  );
};

export default BlogArticle;
