import { toast } from "react-toastify";
import useMutationRequest from "../reactQuery/useMutationRequest";
import useLoginStore, {
  selectResetLoginInfo,
} from "../../../components/login/utils/hooks/useLoginStore";

const BASE_URL = process.env.REACT_APP_BASE_URL || "";

const logoutUserFromApi = async (token) => {
  return await fetch(`${BASE_URL}/users/logoutAll`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const useMutationLogout = () => {
  const resetLoginInfo = useLoginStore(selectResetLoginInfo);

  const { mutateAsync: setUserLogout, isLoading: isLoadingLogout } =
    useMutationRequest({
      mutationFn: logoutUserFromApi,
      onSuccess: (response) => {
        resetLoginInfo();
        toast.success("User logged out!", {
          position: "bottom-right",
        });
        localStorage.removeItem("jwt");
      },
      onError: () => {
        toast.error("Error while logging out!", {
          position: "bottom-right",
        });
      },
    });

  return {
    setUserLogout,
    isLoadingLogout,
  };
};

export default useMutationLogout;
