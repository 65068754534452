import useQueryRequest from "../reactQuery/useQueryRequest";
import { isStringWithCharacters } from "../../validators/stringValidators";

const BASE_URL = process.env.REACT_APP_BASE_URL || "";

const getTokenAuthorizationFromApi = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/users/me`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      localStorage.removeItem("jwt");
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.error("Error en getTokenAuthorizationFromApi:", err);
  }
};
const useAuthorizeToken = (token) => {
  const { data: useAuthorizeTokenData, isLoadingUeAuthorizeTokenData } =
    useQueryRequest({
      enabled: isStringWithCharacters(token),
      queryKey: ["uthorizationToken"],
      queryFn: () => getTokenAuthorizationFromApi(token),
    });

  return { useAuthorizeTokenData, isLoadingUeAuthorizeTokenData };
};
export default useAuthorizeToken;
