import { useEffect } from "react";
import useLoginStore, {
  selectSetLoginInfo,
} from "../../components/login/utils/hooks/useLoginStore";
import useAuthorizeToken from "../hooks/auth/useAuthorizeToken";
import { isObjectWithItems } from "../validators/objectValidators";

export const AuthProvider = ({ children }) => {
  const setLoginInfo = useLoginStore(selectSetLoginInfo);
  const jwt = localStorage.getItem("jwt");
  const { useAuthorizeTokenData } = useAuthorizeToken(jwt);

  useEffect(() => {
    if (isObjectWithItems(useAuthorizeTokenData)) {
      setLoginInfo({ isLoggedIn: true, ...useAuthorizeTokenData });
    }
  }, [useAuthorizeTokenData, setLoginInfo]);

  return <>{children}</>;
};
