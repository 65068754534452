// blogArticlesModalActionTypes.js

import BlogModalForm from "../../components/BlogModalForm";
import BlogModalViewContent from "../../components/BlogModalViewContent";

const blogArticlesModalActionTypes = {
  create: {
    title: "Create New Article",
    description: "Fill out the form below to create a new blog article.",
    isSubmitButton: true,
    isCancelButton: true,
    action: "create",
    render: BlogModalForm, // Componente para crear contenido
  },
  edit: {
    title: "Edit Article",
    description: "Modify the details of the blog article below.",
    isSubmitButton: true,
    isCancelButton: true,
    action: "edit",
    render: BlogModalForm, // Componente para editar contenido
    modalProps: {
      title: "editProfessionalCategory",
      hasConfirm: true,
      onConfirmButtonProps: {
        htmlType: "submit",
        form: "laborAgreementProfessionalCategory",
        confirmText: "actions¬confirm",
        type: "confirm-inverse",
      },
    },
  },
  view: {
    title: "View Article",
    description: "Details of the blog article are shown below.",
    isSubmitButton: false,
    isCancelButton: false, // Puedes poner un botón de cerrar en lugar de cancelar
    action: "view",
    render: BlogModalViewContent, // Componente para ver contenido
  },
  // create: {
  //   actionId: 'create',
  //   Render: LaborAgreementProfessionalCategory,
  //   actionName: 'actions¬create',
  //   modalProps: {
  //     title: 'newProfessionalCategory',
  //     hasConfirm: true,
  //     confirmText: 'createProfessionalCategory',
  //     onConfirmButtonProps: {
  //       htmlType: 'submit',
  //       form: 'laborAgreementProfessionalCategory',
  //       type: 'confirm-inverse',
  //     },
  //   },
  // },
};

export default blogArticlesModalActionTypes;
