import React from "react";
import AppRoutes from "./router/AppRoutes";
import AnimatedCursor from "react-animated-cursor";
import "./assets/scss/main.scss";
import { ToastContainer } from "react-toastify";
import UserAvatar from "./components/userAvatar/UserAvatar";
import Header from "./components/header/Header";
import { BrowserRouter as Router } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Header />
      <UserAvatar />
      <ToastContainer />
      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="255, 160, 1"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
        style={{ zIndex: 10000 }}
      />
      <AppRoutes />
    </Router>
  );
};

export default App;
