import React, { useState } from "react";
import "./ma-method.scss";
import Title from "../title/Title";

const MaMethod = () => {
  const [expanded, setExpanded] = useState(null);

  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div>
      <Title>
        <h1 className="title-section text-left text-sm-center">
          <span>Ma</span> Méthode
        </h1>
        <p className="subtitle text-left text-sm-center">
          Un chemin clair pour réussir 🚀
        </p>
      </Title>
      <section className="ma-method">
        <div className="content-container">
          {/* Introducción */}
          <div className="intro-section">
            <p>
              Forte de mon expérience sur le terrain, je pratique aujourd’hui ma
              propre méthode visant à remettre l’élève au centre des
              apprentissages en créant un espace de confiance, propice à
              l’amélioration. L’objectif est avant tout que les élèves se
              sentent épaulés et soutenus, qu’ils retrouvent l’envie et le désir
              d’apprendre en n’ayant plus peur de l’échec.
            </p>
          </div>

          {/* Etapas */}
          {[
            "Étape 1 : Diagnostic",
            "Étape 2 : Plan d’Action",
            "Étape 3 : Progression Individualisée",
          ].map((title, index) => (
            <div
              className={`step-card ${expanded === index ? "expanded" : ""}`}
              key={index}
              onClick={() => toggleExpand(index)}
            >
              <div className="step-header">
                <h3>{title}</h3>
                <span>{expanded === index ? "▲" : "▼"}</span>
              </div>
              {expanded === index && (
                <div className="step-content">
                  {index === 0 && (
                    <p>
                      Cette étape peut s'effectuer en plusieurs temps : un
                      premier échange pour comprendre les demandes, puis un
                      premier cours pour découvrir les besoins et difficultés de
                      l'élève.
                    </p>
                  )}
                  {index === 1 && (
                    <div>
                      <p>Définir des objectifs clairs :</p>
                      <ul>
                        <li>Cours de soutien en français de la 6e à la 1ère</li>
                        <li>Cours de français général</li>
                        <li>Préparation des épreuves anticipées</li>
                        <li>Préparation du brevet</li>
                        <li>Cours de Français Langue Étrangère</li>
                      </ul>
                    </div>
                  )}
                  {index === 2 && (
                    <p>
                      Préparation de cours personnalisés avec des documents
                      adaptés. Bilan après chaque séance pour suivre la
                      progression et ajuster les méthodes.
                    </p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default MaMethod;
