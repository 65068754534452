import { create } from "zustand";
import { devtools } from "zustand/middleware";

const isDevMode = process.env.NODE_ENV === "development";

const zustandCreateStore = (storeCreator, name) => {
  if (!isDevMode) {
    return create(storeCreator, null);
  }
  try {
    const wrappedStoreCreator = (set, get, api) => {
      const wrappedSet = (payload) => {
        const payloadKeys = Object.keys(payload).toString();
        const action = {
          type: `set ${payloadKeys}`,
          payload,
        };
        return set(payload, false, action);
      };

      return storeCreator(wrappedSet, get, api);
    };
    return create(
      devtools(wrappedStoreCreator, { name, serialize: { options: false } })
    );
  } catch (error) {
    console.log("🚀 ~ zustandCreateStore ~ error:", error);
    return create(
      devtools(storeCreator, { name, serialize: { options: false } })
    );
  }
};

export default zustandCreateStore;
