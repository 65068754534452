import { toast } from "react-toastify";
import useMutationRequest from "../../../../utils/hooks/reactQuery/useMutationRequest";
import {
  getArticleByIdQueryKeys,
  getArticlesQueryKeys,
} from "../../../../utils/api/queryKeys/articlesQueryKeys/articlesQueryKeys";
import useQueryClient from "../../../../utils/hooks/reactQuery/useQueryClient";

const BASE_URL = process.env.REACT_APP_BASE_URL || "";

const createArticlesFromApi = async ({ token, formData }) => {
  const response = await fetch(`${BASE_URL}/articles`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Error al crear el artículo");
  }

  return response.json();
};
const editArticlesFromApi = async ({ token, formData, id }) => {
  const response = await fetch(`${BASE_URL}/articles/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Error al editar el artículo");
  }

  return response.json();
};

const useMutationArticles = (id) => {
  const queryClient = useQueryClient();
  const { mutateAsync: createArticle, isLoading: isLoadingCreateArticle } =
    useMutationRequest({
      mutationFn: createArticlesFromApi,
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: getArticlesQueryKeys(),
        });
      },
      onSuccess: () => {
        toast.success("Artículo creado con éxito!", {
          position: "bottom-right",
        });
      },
      onError: (error) => {
        toast.error(error.message || "Error al crear el artículo", {
          position: "bottom-right",
        });
      },
    });
  const { mutateAsync: editArticle, isLoading: isLoadingEditArticle } =
    useMutationRequest({
      mutationFn: editArticlesFromApi,
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: getArticlesQueryKeys(),
        });
        queryClient.invalidateQueries({
          queryKey: getArticleByIdQueryKeys({ articleId: id }),
        });
      },
      onSuccess: () => {
        toast.success("Artículo editado con éxito!", {
          position: "bottom-right",
        });
      },
      onError: (error) => {
        toast.error(error.message || "Error al editar el artículo", {
          position: "bottom-right",
        });
      },
    });

  return {
    createArticle,
    editArticle,
    isLoadingCreateArticle,
    isLoadingEditArticle,
  };
};

export default useMutationArticles;
