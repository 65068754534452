import About from "../../../../components/about/About";
import Blog from "../../../../components/blog/Blog";
import Contact from "../../../../components/contact/Contact";
import Hero from "../../../../components/hero/Hero";
import Login from "../../../../components/login/Login";
import MaMethod from "../../../../components/maMethod/MaMethod";

export const menuItem = [
  {
    icon: "fa-home",
    menuName: "Accueil",
    itemId: "accueil",
    component: <Hero />,
    className: "home",
    path: "/",
  },
  {
    icon: "fa-list-alt",
    menuName: "Ma méthode",
    itemId: "maMethod",
    component: <MaMethod />,
    className: "maMethod",
    path: "/maMethod",
  },
  {
    icon: "fa-user",
    menuName: "Qui suis-je ?",
    itemId: "about",
    component: <About />,
    className: "about",
    path: "/about",
  },
  {
    icon: "fa-envelope-open",
    menuName: "Contact",
    itemId: "contact",
    component: <Contact />,
    className: "contact",
    path: "/contact",
  },
  // { icon: "fa-user", menuName: "Témoignages", component: <Temoinages />, className: "temoinage", path: "/" },
  {
    icon: "fa-comments",
    menuName: "Blog",
    itemId: "blog",
    component: <Blog />, // Cambié "Blog" por "BlogPage" si este es el nombre correcto
    className: "blog",
    path: "/articles",
  },
  {
    icon: "fa-arrow-circle-o-right",
    menuName: "Login",
    itemId: "login",
    component: <Login />,
    className: "login",
    path: "/login",
  },
];
