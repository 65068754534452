import React, { useEffect, useMemo, useState } from "react";
import { isString } from "antd/es/button";
import useGetArticles from "../utils/hooks/useGetArticles";
import { useParams } from "react-router-dom";
import { base64ToArrayBuffer } from "../../../utils/functions/base64ToArrayBuffer";
import Title from "../../title/Title";

const BlogModalViewContent = () => {
  const { articleId } = useParams();
  const { getArticleById } = useGetArticles(articleId);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const createdAtFormatted = useMemo(() => {
    if (isString(selectedArticle?.createdAt)) {
      const date = new Date(selectedArticle.createdAt);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return undefined;
  }, [selectedArticle?.createdAt]);

  useEffect(() => {
    if (getArticleById) {
      setSelectedArticle(getArticleById);
    }
  }, [getArticleById]);

  useEffect(() => {
    if (selectedArticle?.headerImage?.data) {
      const arrayBuffer = base64ToArrayBuffer(selectedArticle.headerImage.data);
      const blob = new Blob([arrayBuffer], {
        type: selectedArticle.headerImage.contentType,
      });
      const url = URL.createObjectURL(blob);
      setImageUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setImageUrl(`img/blog/blog-post-1.jpg`);
    }
  }, [selectedArticle]);

  if (!selectedArticle) {
    return <p>Cargando...</p>;
  }

  const {
    title,
    detailedInfo: { textContent, quotedText, author, tags } = {},
  } = selectedArticle;

  return (
    <div className="custom-modal">
      <div className="box_inner blog-post">
        <article>
          <Title>
            <h1>
              <span>{title}</span>
            </h1>
            <meta
              name="description"
              content={`${title} - ${textContent?.substring(0, 150) || ""}`}
            />
          </Title>

          <div className="meta roboto-font">
            <span aria-label="author">
              <i className="fa fa-user" aria-hidden="true"></i> {author}
            </span>
            {createdAtFormatted && (
              <time className="date" dateTime={createdAtFormatted}>
                <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                {createdAtFormatted}
              </time>
            )}
            <span aria-label="tags">
              <i className="fa fa-tags" aria-hidden="true"></i>{" "}
              {tags?.join(", ")}
            </span>
          </div>
          <figure>
            <img src={imageUrl} className="img-fluid" alt={title} />
            <figcaption>{title} - Article Image</figcaption>
          </figure>

          <section className="blog-excerpt roboto-font pb-5">
            <div
              style={{
                whiteSpace: "pre-wrap",
                border: "none",
                padding: "8px",
                borderRadius: "4px",
                minHeight: "100px",
              }}
            >
              {textContent}
            </div>
            <blockquote className="quotebox" cite={author}>
              <div className="icon">
                <img src="img/blog/quote.svg" alt="Quote icon" />
              </div>
              <p>{quotedText}</p>
            </blockquote>
          </section>
        </article>
      </div>
    </div>
  );
};

export default BlogModalViewContent;
