import articlesKeys from "./articlesKeys";

export const getArticlesQueryKeys = (filters) => {
  const { articles } = articlesKeys;
  const query = [articles];
  if (filters) {
    query.push(filters);
  }
  return query;
};
export const getArticleByIdQueryKeys = ({ articleId, filters }) => {
  const { articleById } = articlesKeys;
  const query = [articleById, articleId];
  if (filters) {
    query.push(filters);
  }
  return query;
};
