import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ScrollTopBehavior from "../components/scrollTop/ScrollTopBehavior";
import Article from "../components/article/Article";

const Hero = lazy(() => import("../components/hero/Hero"));
const NotFound = lazy(() => import("../views/NotFound"));
const Blog = lazy(() => import("../components/blog/Blog"));
const MaMethod = lazy(() => import("../components/maMethod/MaMethod"));
const About = lazy(() => import("../components/about/About"));
const Contact = lazy(() => import("../components/contact/Contact"));
const Login = lazy(() => import("../components/login/Login"));

const AppRoutes = () => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <ScrollTopBehavior />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/maMethod" element={<MaMethod />} />
          <Route path="/about" element={<About />} />
          <Route path="/articles" element={<Blog />} />
          <Route path="/articles/:articleId" element={<Article />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
