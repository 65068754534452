import React from "react";
import "./title.scss";

export const Title = ({ children }) => {
  return (
    <header className="title-section ">
      <div className="title-section text-left text-sm-center">{children}</div>
      <hr className="separator separator-margin" />
    </header>
  );
};

export default Title;
