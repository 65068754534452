import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, TabList, Tab } from "react-tabs"; // Asegúrate de importar correctamente estos componentes
import { menuItem } from "./utils/constants/menuItemConstants";
import "./header.scss";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const activeTab = menuItem.find((item) => item.path === pathname)?.itemId;

  const handleTabClick = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Tabs
        selectedIndex={menuItem.findIndex((item) => item.itemId === activeTab)}
      >
        <div className="header">
          <TabList className="icon-menu revealator-slideup revealator-once revealator-delay1">
            {menuItem.map((item, index) => (
              <Tab
                className="icon-box"
                key={index}
                onClick={() => handleTabClick(item.path)}
              >
                <i className={`fa ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
              </Tab>
            ))}
          </TabList>
        </div>
      </Tabs>
    </div>
  );
};
export default Header;
