import { toast } from "react-toastify";
import useMutationRequest from "../reactQuery/useMutationRequest";
import useLoginStore, {
  selectSetLoginInfo,
} from "../../../components/login/utils/hooks/useLoginStore";

const BASE_URL = process.env.REACT_APP_BASE_URL || "";

const authenticateUserFromApi = async ({ email, password }) => {
  return await fetch(`${BASE_URL}/users/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Login failed");
    }

    return response.json();
  });
};

const useMutationLogin = () => {
  const setLoginInfo = useLoginStore(selectSetLoginInfo);
  const { mutateAsync: setUserLogin, isLoading: isLoadingLogin } =
    useMutationRequest({
      mutationFn: authenticateUserFromApi,
      onSuccess: (data) => {
        setLoginInfo({ isLoggedIn: true, ...data });
        toast.success("User logged in!", {
          position: "bottom-right",
        });
        localStorage.setItem("jwt", data.token);
      },
      onError: () => {
        toast.error("Error while logging in!", {
          position: "bottom-right",
        });
      },
    });

  return {
    setUserLogin,
    isLoadingLogin,
  };
};

export default useMutationLogin;
