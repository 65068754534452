import React from "react";
import { Form, Input, Button, Card } from "antd";
import useMutationLogin from "../../utils/hooks/auth/useMutationLogin";
import { isStringWithCharacters } from "../../utils/validators/stringValidators";
import useMutationLogout from "../../utils/hooks/auth/useMutationLogout";
import useLoginStore, { selectLoginInfo } from "./utils/hooks/useLoginStore";
import "./login.scss";
import Title from "../title/Title";

const Login = () => {
  const jwt = localStorage.getItem("jwt");
  const { setUserLogin, isLoadingLogin } = useMutationLogin();
  const { setUserLogout, isLoadingLogout } = useMutationLogout();

  const { isLoggedIn } = useLoginStore(selectLoginInfo);

  const [form] = Form.useForm();

  const handleLogin = ({ email, password }) => {
    if (isStringWithCharacters(email) && isStringWithCharacters(password)) {
      setUserLogin({ email, password }).catch((err) => console.log(err));
    }
  };

  const handleLogout = () => {
    if (isLoggedIn) {
      setUserLogout(jwt);
    }
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      handleLogin(values);
    });
  };

  return (
    <>
      <Title>
        <h1>
          lo<span>gin</span>
        </h1>
      </Title>
      <div className="d-flex justify-content-center align-items-center login-card_wrapper">
        <div className="align-items-center">
          <Card className="login-card">
            {!isLoggedIn ? (
              <Form
                className="login-card_form"
                form={form}
                onFinish={handleSubmit}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isLoadingLogin}
                >
                  Login
                </Button>
              </Form>
            ) : (
              <Button
                className="login-card_button-submit transparent-button"
                onClick={handleLogout}
                type="link"
                disabled={isLoadingLogout}
              >
                Logout
              </Button>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

export default Login;
