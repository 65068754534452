import React from "react";
import PersonalInfo from "./components/PersonalInfo";
import Achievements from "./components/Achievements";
import Experience from "./components/Experience";
import Education from "./components/Education";
import { WhyMe } from "./components/whyMe";
import "./about.scss";
import Title from "../title/Title";

const About = () => {
  return (
    <div>
      <Title>
        <h1>
          À propos de <span>moi</span>
        </h1>
      </Title>
      <section className="about main-content">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 col-12">
              <div className="row">
                <div className="col-12">
                  <h3 className="text-uppercase custom-title mb-0 ft-wt-600">
                    Informations personnelles
                  </h3>
                </div>

                <div className="col-12">
                  <PersonalInfo />
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
              <Achievements />
            </div>
          </div>
        </div>
        <hr className="separator" />
        <div className="container">
          <WhyMe />
          <div className="row">
            <div className="col-12">
              <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
                Expériences <span>&</span> Formations
              </h3>
            </div>
            <div className="col-lg-6">
              <div className="resume-box">
                <Experience />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="resume-box">
                <Education />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
