import { loginNames } from "../../../../utils/constants/storeNames";
import zustandCreateStore from "../../../../utils/misc/zustand/zustandCreateStore";

const initialState = {
  token: undefined,
  avatar: undefined,
  isLoggedIn: false,
};

const useLoginStore = zustandCreateStore(
  (set) => ({
    ...initialState,
    setLoginInfo: (info) =>
      set((state) => (info ? { ...state, ...info } : { ...initialState })),
    resetLoginInfo: () => set({ ...initialState }),
  }),
  loginNames.loggedUser
);

export default useLoginStore;

export const selectSetLoginInfo = (state) => state.setLoginInfo;
export const selectResetLoginInfo = (state) => state.resetLoginInfo;
export const selectLoginInfo = (state) => state;
