import { modalActionTypesNames } from "../../../../utils/constants/storeNames";
import zustandCreateStore from "../../../../utils/misc/zustand/zustandCreateStore";

const initialState = {
  action: null,
};

const useModalActionTypeStore = zustandCreateStore(
  (set) => ({
    ...initialState,
    setModalActionType: (info = {}) => set((state) => ({ ...state, ...info })),
    resetModalActionType: () => set({ ...initialState }),
  }),
  modalActionTypesNames.modalActionTypes
);

export default useModalActionTypeStore;

export const selectSetModalActionType = (state) => state.setModalActionType;
export const selectResetModalActionType = (state) => state.resetModalActionType;
export const selectModalActionType = (state) => state.action;
