import React from "react";
import Social from "../social/Social";
import { heroContent } from "./utils/constants/hereContentConstants";
import "./hero.scss";

const Hero = () => {
  const heroImage = `${process.env.PUBLIC_URL}/img/professeure-de-français.JPG`;

  return (
    <div className="hero-component container-fluid main-container p-0 text-center">
      <div className="row details-container align-items-center">
        <div
          className="col-lg-4 background-image position-fixed"
          style={{ backgroundImage: `url(${heroImage})` }}
          aria-hidden="true"
        ></div>

        <div className="col-12 col-lg-8 offset-lg-4 details text-center text-lg-start">
          <div>
            <h1 className="title text-uppercase poppins-font">
              {heroContent.heroTitleName}
              <span className="designation">{heroContent.heroDesignation}</span>
            </h1>
            <p className="description roboto-font">
              {heroContent.heroDescriptions}
            </p>
            <p className="description roboto-font">
              {heroContent.heroDescriptions2ndParagraph}
            </p>

            <div className="social">
              <Social />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
