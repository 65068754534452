import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isArrayWithItems } from "../../utils/validators/arrayValidators";
import { Button } from "antd";
import useGetArticles from "./utils/hooks/useGetArticles";
import BlogArticlesSkeleton from "./components/BlogArticlesSkeleton";
import useLoginStore, {
  selectLoginInfo,
} from "../login/utils/hooks/useLoginStore";
import BlogArticle from "./components/blogArticle/BlogArticle";
import useModalActionTypeStore, {
  selectResetModalActionType,
  selectSetModalActionType,
} from "./utils/hooks/useModalActionTypeStore";
import blogArticlesModalActionTypes from "./utils/constants/blogArticlesModalActionTypes";
import BlogArticleModal from "./components/BlogArticleModal";
import { useNavigate } from "react-router-dom";
import "./blog.scss";
import Title from "../title/Title";

const Blog = () => {
  const setModalActionType = useModalActionTypeStore(selectSetModalActionType);
  const resetModalActionType = useModalActionTypeStore(
    selectResetModalActionType
  );

  const [selectedArticleId, setSelectedArticleId] = useState(undefined);

  const [isAllImagesLoaded, setIsAllImagesLoaded] = useState(false);

  const navigate = useNavigate();
  const { isLoggedIn } = useLoginStore(selectLoginInfo);
  const { getArticles, isLoadingGetArticles, getArticleById } =
    useGetArticles(selectedArticleId);
  const articlesFormatted = useMemo(() => {
    if (isArrayWithItems(getArticles)) {
      return getArticles;
    }
    return [];
  }, [getArticles]);

  useEffect(() => {
    const preloadImages = async () => {
      if (isArrayWithItems(articlesFormatted)) {
        const imagePromises = articlesFormatted.map((article) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = article.imageUrl;
            img.onload = resolve;
            img.onerror = resolve;
          });
        });
        await Promise.all(imagePromises);
        setIsAllImagesLoaded(true);
      }
    };

    preloadImages();
  }, [articlesFormatted]);

  const handleSelectedArticle = useCallback(
    (articleId) => {
      navigate(`/articles/${articleId}`);
    },
    [navigate]
  );

  const handleAddArticleClick = () => {
    setModalActionType({ action: blogArticlesModalActionTypes.create.action });
  };
  const handleEditArticleClick = (articleId) => {
    setModalActionType({ action: blogArticlesModalActionTypes.edit.action });
    setSelectedArticleId(articleId);
  };

  const handleModalClose = () => {
    setSelectedArticleId(undefined);
    resetModalActionType();
  };

  const handleModalOnFormFinish = () => {
    resetModalActionType();
  };

  return (
    <>
      <Title>
        <h1>
          my <span>blog</span>
        </h1>
      </Title>
      <div className="blog_container">
        {isLoggedIn && !isLoadingGetArticles && (
          <div className="blog_container-add">
            <div className="mb-3">
              <Button type="primary" onClick={handleAddArticleClick}>
                Add New Article
              </Button>
            </div>
          </div>
        )}
        {isLoadingGetArticles || !isAllImagesLoaded ? (
          <div className="blog_container-articles_skeleton">
            <BlogArticlesSkeleton />
          </div>
        ) : (
          <div className="blog_container-articles_wrapper">
            {articlesFormatted.map((article) => (
              <BlogArticle
                article={article}
                handleSelectedArticle={handleSelectedArticle}
                handleEditArticleClick={handleEditArticleClick}
              />
            ))}
          </div>
        )}
      </div>
      <BlogArticleModal
        onCancel={handleModalClose}
        handleModalOnFormFinish={handleModalOnFormFinish}
        selectedArticle={getArticleById}
      />
    </>
  );
};
export default Blog;
