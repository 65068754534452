import { toast } from "react-toastify";
import useMutationRequest from "../../../../utils/hooks/reactQuery/useMutationRequest";
import { getArticlesQueryKeys } from "../../../../utils/api/queryKeys/articlesQueryKeys/articlesQueryKeys";
import useQueryClient from "../../../../utils/hooks/reactQuery/useQueryClient";

const BASE_URL = process.env.REACT_APP_BASE_URL || "";

const removeArticlesFromApi = async ({ token, id }) => {
  const response = await fetch(`${BASE_URL}/articles/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Error al eliminar el artículo");
  }

  return response.json();
};

const useMutationRemoveArticles = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: removeArticles, isLoading: isLoadingRemoveArticles } =
    useMutationRequest({
      mutationFn: removeArticlesFromApi,
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: getArticlesQueryKeys(),
        });
      },
      onSuccess: () => {
        toast.success("Artículo eliminado con éxito!", {
          position: "bottom-right",
        });
      },
      onError: (error) => {
        toast.error(error.message || "Error al eliminar el artículo", {
          position: "bottom-right",
        });
      },
    });

  return {
    removeArticles,
    isLoadingRemoveArticles,
  };
};

export default useMutationRemoveArticles;
