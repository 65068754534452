import React from "react";
import { SiLinkedin, SiInstagram } from "react-icons/si";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsFillEnvelopeFill } from "react-icons/bs";
import "./social.scss";

const SocialShare = [
  {
    iconName: <SiInstagram />,
    link: "https://www.instagram.com/laurie.escobedo.pro/",
  },
  {
    iconName: <SiLinkedin />,
    link: "https://www.linkedin.com/in/laurie-escobedo/",
  },
  {
    iconName: <BsFillEnvelopeFill />,
    email: "escobedo.laurie.pro@gmail.com",
  },
];

const Social = () => {
  return (
    <ul className="social list-unstyled pt-1">
      {SocialShare.map((socialApp, index) => (
        <li className="d-inline-flex p-2" key={index} align="center">
          <a
            className="d-flex justify-content-center align-items-center cursor-none"
            href={socialApp.link}
            align="center"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              return socialApp.email
                ? (navigator.clipboard.writeText(socialApp.email),
                  toast.success("E-mail copié.", {
                    position: "bottom-right",
                  }))
                : null;
            }}
          >
            {socialApp.iconName}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
