import {
  getArticleByIdQueryKeys,
  getArticlesQueryKeys,
} from "../../../../utils/api/queryKeys/articlesQueryKeys/articlesQueryKeys";
import useQueryRequest from "../../../../utils/hooks/reactQuery/useQueryRequest";
import { isStringWithCharacters } from "../../../../utils/validators/stringValidators";

const BASE_URL = process.env.REACT_APP_BASE_URL || "";

const getArticlesFromApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/articles`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error on getting articles: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error on getting articles:", err);
  }
};

const getArticleByIdFromApi = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/articles/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error on getting article: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (err) {
    console.error("Error on getting article:", err);
  }
};

const useGetArticles = (id) => {
  const { data: getArticles, isLoading: isLoadingGetArticles } =
    useQueryRequest({
      enabled: true,
      queryKey: getArticlesQueryKeys(), // Clave única para todos los articulos
      queryFn: getArticlesFromApi,
    });

  const { data: getArticleById, isLoading: isLoadingArticleById } =
    useQueryRequest({
      enabled: isStringWithCharacters(id), // Solo se ejecuta si `id` tiene un valor
      queryKey: getArticleByIdQueryKeys({ articleId: id }), // Clave única para el artículo individual
      queryFn: () => getArticleByIdFromApi(id),
    });

  return {
    getArticles,
    getArticleById,
    isLoadingGetArticles,
    isLoadingArticleById,
  };
};

export default useGetArticles;
