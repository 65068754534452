import React, { useMemo } from "react";
import styles from "./UserAvatar.module.scss";
import useLoginStore, {
  selectLoginInfo,
} from "../login/utils/hooks/useLoginStore";
import { isStringWithCharacters } from "../../utils/validators/stringValidators";

const UserAvatar = () => {
  const loginIfo = useLoginStore(selectLoginInfo);
  const { isLoggedIn, ...userInfoMemoized } = useMemo(
    () => loginIfo,
    [loginIfo]
  );

  const isUserInfoPopulated = useMemo(
    () => isStringWithCharacters(userInfoMemoized.name),
    [userInfoMemoized.name]
  );

  return isLoggedIn && isUserInfoPopulated ? (
    <div className={styles["user-avatar"]}>
      {userInfoMemoized?.avatar ? (
        <img
          src={userInfoMemoized?.avatar}
          alt="User Avatar"
          className={styles["avatar-image"]}
        />
      ) : (
        <div className={styles["avatar-placeholder"]} />
      )}
      <span className={styles["user-name"]}>
        {userInfoMemoized?.name ? userInfoMemoized?.name : ""}
      </span>
    </div>
  ) : null;
};

export default UserAvatar;
