import { useMutation } from "@tanstack/react-query";

const useMutationRequest = ({
  mutationFn,
  onSuccess,
  onError,
  onSettled,
  onMutate,
}) => {
  const {
    mutate,
    mutateAsync,
    data,
    isPending: isLoading,
    isError,
  } = useMutation({
    mutationFn,
    onSuccess,
    onError,
    onSettled,
    onMutate,
  });
  return {
    mutateAsync,
    mutate,
    data,
    isLoading,
    isError,
  };
};

export default useMutationRequest;
