import { Modal, Button, Form } from "antd";
import useMutationArticles from "../utils/hooks/useMutationArticles";
import useModalActionTypeStore, {
  selectModalActionType,
} from "../utils/hooks/useModalActionTypeStore";
import { isStringWithCharacters } from "../../../utils/validators/stringValidators";
import blogArticlesModalActionTypes from "../utils/constants/blogArticlesModalActionTypes";
import { useMemo } from "react";

const BlogArticleModal = ({
  onCancel,
  handleModalOnFormFinish,
  selectedArticle,
}) => {
  const [form] = Form.useForm();
  const modalActionType = useModalActionTypeStore(selectModalActionType);
  const { createArticle, editArticle } = useMutationArticles(
    selectedArticle?.id
  );
  const token = localStorage.getItem("jwt");

  const onFormFinish = (formData) => {
    if (modalActionType === blogArticlesModalActionTypes.create.action) {
      createArticle({ formData, token });
      handleModalOnFormFinish();
      return;
    }

    formData.forEach((value, key) => {
      console.log(`${key}:`, value);
    });
    editArticle({ formData, token, id: selectedArticle.id });
    handleModalOnFormFinish();
  };

  const {
    title,
    description,
    render: RenderComponent,
    isSubmitButton,
    isCancelButton,
  } = useMemo(() => {
    return blogArticlesModalActionTypes[modalActionType] || {};
  }, [modalActionType]);

  const additionalProps = {
    onFormFinish,
    form,
    selectedArticle,
  };
  return (
    <Modal
      zIndex={998}
      open={isStringWithCharacters(modalActionType)}
      title={title}
      description={description}
      onCancel={onCancel}
      destroyOnClose
      className="custom-modal"
      style={{ padding: 0 }}
      overlayClassName="custom-overlay dark"
      width="auto"
      footer={[
        isCancelButton && (
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>
        ),
        isSubmitButton && (
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Submit
          </Button>
        ),
      ].filter(Boolean)}
      motion={null}
    >
      <div className="box_inner blog-post">
        {RenderComponent && <RenderComponent {...additionalProps} />}
      </div>
    </Modal>
  );
};

export default BlogArticleModal;
