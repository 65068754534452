import { useEffect, useState } from "react";
import { Form, Input, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { isArray } from "../../../utils/validators/arrayValidators";
import TextArea from "../../display/textArea/TextArea";

const BlogModalForm = ({ selectedArticle, onFormFinish, form }) => {
  const [fileList, setFileList] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (selectedArticle && Object.keys(selectedArticle).length > 0) {
      setInitialValues({
        title: selectedArticle.title,
        subTitle: selectedArticle.subTitle,
        detailedInfo: selectedArticle.detailedInfo,
      });
      if (selectedArticle.headerImage) {
        setFileList([
          {
            uid: "-1",
            name: "headerImage.png",
            status: "done",
            url: selectedArticle.headerImage,
          },
        ]);
      }
      form.setFieldsValue({
        title: selectedArticle.title,
        subTitle: selectedArticle.subTitle,
        detailedInfo: selectedArticle.detailedInfo,
      });
    }
  }, [selectedArticle, form]);

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleFinish = (formValues) => {
    console.log("🚀 ~ handleFinish ~ formValues:", formValues);
    const formData = new FormData();

    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      if (value !== undefined && value !== "") {
        if (key === "headerImage" && fileList.length > 0) {
          formData.append(key, fileList[0].originFileObj);
        } else if (key === "detailedInfo") {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
    });

    onFormFinish(formData);
  };
  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      initialValues={initialValues}
      encType="multipart/form-data"
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[{ required: true, message: "Please enter the title" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="subTitle"
        label="Subtitle"
        rules={[{ required: true, message: "Please enter the subtitle" }]}
      >
        <Input.TextArea rows={2} />
      </Form.Item>
      <Form.Item
        name="headerImage"
        label="Header Image"
        valuePropName="fileList"
        getValueFromEvent={(event) =>
          isArray(event) ? event : event && [event.file]
        }
      >
        <Upload
          name="headerImage"
          listType="picture"
          beforeUpload={() => false}
          onChange={handleFileChange}
          fileList={fileList}
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name={["detailedInfo", "tags"]}
        label="Tags"
        rules={[{ required: true, message: "Please enter tags" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["detailedInfo", "textContent"]}
        label="Text Content"
        rules={[{ required: true, message: "Please enter the text content" }]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item name={["detailedInfo", "quotedText"]} label="Quoted Text">
        <Input.TextArea rows={3} />
      </Form.Item>
    </Form>
  );
};

export default BlogModalForm;
